import { jwtControl, setAuth } from '../auth/auth';
import type { Subscription } from '../graphql/generated-types';
import { useAuthenticateUserOtpMutation } from '../graphql/generated-types';
import { useAuth } from './useAuth';
import { useSubscribeUser } from './useSubscribeUser';

export const useAuthenticateOTP = () => {
  const auth = useAuth();

  const [
    authenticate,
    { loading: authenticateLoading, error: authenticateError },
  ] = useAuthenticateUserOtpMutation();

  const {
    subscribeUser,
    loading: sLoading,
    error: sError,
  } = useSubscribeUser();

  const authenticateOTP = async (
    id: string,
    otpKey: string,
    isRegistering: boolean
  ) => {
    let user: any = null;
    try {
      const authenticatedUser = await authenticate({
        variables: { id, otpKey, role: 'px' },
      });

      if (
        authenticatedUser.data?.authenticateUserOTP?.user &&
        !authenticateError
      ) {
        const data = authenticatedUser.data.authenticateUserOTP;
        const jwt = data.jwt as string;
        // set auth in local storage
        user = { ...data.user };
        user.otp = true;
        if (data.userProfile) {
          user.userProfile = { ...data.userProfile };
        }
        setAuth(user, jwt, data.refresh as string, true);
        if (data.userProfile && data.userProfile.mobile) {
          jwtControl.setMobileVerified(true);
        }
        auth.login(user, jwt);
        if (isRegistering) {
          await subscribeUser(
            user.id,
            data.subscriptions as Partial<Subscription>[]
          );
        }
      }
      return user;
    } catch {
      // handled by error
      return null;
    }
  };

  return {
    authenticateOTP,
    loading: authenticateLoading || sLoading,
    error: authenticateError || sError,
  };
};
