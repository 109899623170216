import { usePublicProgram } from '@drugfreesleep/app/(program)/course/program.gql';
import useWindowSize from '@drugfreesleep/utils/useWindowSize';
import { Alert, Button, Label } from 'flowbite-react';
import { useRouter } from 'next/router';
import React, { useEffect, useReducer, useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';
import PasswordChecklist from 'react-password-checklist';

import { useLogger } from '../analytics/useImpression';
import { jwtControl } from '../auth/auth';
import type { UserRegistrationMutation } from '../graphql/generated-types';
import { useSetEmailMutation } from '../graphql/generated-types';
import { SetDisplayName } from './SetDisplayName';
import { useAuth } from './useAuth';
import { useLogin } from './useLogin';
import { useRegistration } from './useRegistration';
import { useSubscribeUser } from './useSubscribeUser';

/* eslint-disable unused-imports/no-unused-vars */

type State = {
  username: string;
  password: string;
  confirmationMessage?: string | null;
  email: string;
  remember: boolean;
};

const formReducer = (
  state: State,
  action: { type: string; field?: string; value?: string }
) => {
  if (!action.field) return state;
  switch (action.type) {
    case 'updateFieldValue':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

interface UsernameFormProps {
  showRegister?: boolean;
  setShowMobileOTP: (a: boolean) => void;
  isModal?: boolean;
  routeHome?: boolean;
}

export const UsernameForm = ({
  showRegister = false,
  setShowMobileOTP,
  isModal = false,
  routeHome,
}: UsernameFormProps) => {
  const auth = useAuth();
  const log = useLogger();
  const { auth: jwtAuth } = jwtControl.getAuth();
  const router = useRouter();
  const { program, loading } = usePublicProgram();
  const { login, loading: loginLoading, error: loginError } = useLogin();
  const [registerUser, { error: registerError, loading: registerLoading }] =
    useRegistration();
  const [setEmail, { loading: setEmailLoading, error: setEmailError }] =
    useSetEmailMutation();

  const initialState = {
    username: jwtAuth ? jwtAuth?.user?.username : '',
    password: '',
    email: '',
    confirmationMessage: null,
    remember: true,
  };
  const [state, dispatch] = useReducer<React.Reducer<State, any>>(
    formReducer,
    initialState
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showSignIn, setShowSignIn] = useState(!showRegister);
  const { isMobile } = useWindowSize();
  const [validPassword, setValidPassword] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showRegisterError, setShowRegisterError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const [showEmailPrompt, setShowEmailPrompt] = useState(false);

  const { subscribeUser, error: sError } = useSubscribeUser();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const goToToday = () => {
    router.push(program ? `/p/${program.slug}/today` : `/today`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'updateFieldValue',
      field: event.target.name,
      value: event.target.value,
    });

    // Hide alert messages when user starts typing
    setShowLoginError(false);
    setShowRegisterError(false);
    dispatch({
      type: 'updateFieldValue',
      field: 'confirmationMessage',
      value: null,
    });
  };

  useEffect(() => {
    if (loginError) {
      setShowLoginError(true);
    }
    if (registerError) {
      setShowRegisterError(true);
    }
    if (setEmailError) {
      setShowEmailError(true);
    }
  }, [loginError, registerError, setEmailError]);

  const handleSetEmail = async (email: string) => {
    await setEmail({
      variables: {
        email,
        domain: window.location.origin,
      },
    });
    goToToday();
  };

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (state.email) {
      handleSetEmail(state.email);
    }
  };

  const handleCreateSub = async (data: UserRegistrationMutation) => {
    if (program && program.id && !showSignIn && data?.registerUser?.user?.id) {
      await subscribeUser(data?.registerUser?.user?.id, [] as any);
    } else {
      goToToday();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (state.confirmationMessage) {
      dispatch({
        type: 'updateFieldValue',
        field: 'confirmationMessage',
        value: null,
      });
    }

    try {
      if (!showSignIn) {
        const { data } = await registerUser({
          variables: {
            username: state.username,
            password: state.password,
          },
        });
        if (
          data?.registerUser &&
          data.registerUser.user?.id &&
          data.registerUser.user?.username &&
          data.registerUser.jwt
        ) {
          // Proceed to mobile verification
          await handleCreateSub(data);
          setShowEmailPrompt(true);
        }
      } else {
        await login(
          state.username,
          state.password,
          state.remember,
          showRegister
        );
        goToToday();
      }
    } catch {
      // Error handling
    }
  };
  useEffect(() => {
    if (
      auth.authenticated &&
      routeHome &&
      !state.password &&
      !loading &&
      !showEmailPrompt
    ) {
      goToToday();
    }
  }, [auth, loading]);

  return (
    <div>
      {!showEmailPrompt ? (
        <form className="flex flex-col gap-4 md:mx-16" onSubmit={handleSubmit}>
          <div className="mb-2 text-center text-2xl font-semibold text-black md:text-3xl">
            {showSignIn ? 'Sign In' : 'Create your account'}
          </div>
          <div className="flex justify-center">
            <div>
              <div className="mb-2 block">
                <Label
                  style={{ color: 'black' }}
                  htmlFor="username"
                  value="Your username"
                />
              </div>
              <input
                className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700 "
                id="username"
                type="text"
                name="username"
                style={{
                  maxWidth: '600px',
                  minWidth: isMobile ? '300px' : '350px',
                }}
                value={state.username}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="mb-3 flex justify-center">
            <div>
              <div className="mb-2 block">
                <Label
                  style={{ color: 'black' }}
                  htmlFor="password1"
                  value="Password"
                />
              </div>
              <div className="relative">
                <input
                  id="password1"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={state.password}
                  style={{
                    maxWidth: '600px',
                    minWidth: isMobile ? '300px' : '350px',
                  }}
                  onChange={handleInputChange}
                  className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700 "
                />
                {showSignIn && (
                  <div className="mt-2 flex justify-end">
                    <div
                      className="cursor-pointer underline"
                      onClick={() => {
                        router.push(
                          program
                            ? `/p/${program.slug}/reset-password`
                            : `/reset-password`
                        );
                      }}
                    >
                      Forgot Password?
                    </div>
                  </div>
                )}
                {!showSignIn &&
                  state.password.length >= 3 &&
                  !showLoginError &&
                  !showRegisterError &&
                  !state.confirmationMessage && (
                    <div className="absolute left-0 top-full mt-2">
                      <PasswordChecklist
                        rules={[
                          'minLength',
                          'specialChar',
                          'number',
                          'capital',
                        ]}
                        iconSize={10}
                        className="text-sm"
                        invalidColor="#808080"
                        minLength={8}
                        value={state.password}
                        onChange={(isValid) => {
                          setValidPassword(isValid);
                        }}
                      />
                    </div>
                  )}
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 h-10 px-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center ">
            <div className="absolute bottom-12 flex flex-col justify-center">
              <div className="mb-8 flex w-full justify-center">
                {showSignIn ? (
                  <Button
                    style={{
                      width: '170px',
                      borderRadius: '2rem',
                      backgroundColor: 'rgb(30 64 175)',
                    }}
                    className="bg-blue-800"
                    type="submit"
                    disabled={loginLoading}
                  >
                    <div className="mr-1">Sign In</div>
                    <IoArrowForward />
                  </Button>
                ) : (
                  <Button
                    style={{
                      width: '170px',
                      borderRadius: '2rem',
                      backgroundColor: 'rgb(30 64 175)',
                    }}
                    className="bg-blue-800"
                    type="submit"
                    disabled={
                      registerLoading || (showRegister && !validPassword)
                    }
                  >
                    <div className="mr-1">Register</div>
                    <IoArrowForward />
                  </Button>
                )}
              </div>
              <div className="flex flex-col justify-center text-center align-baseline text-black">
                <div className="mb-2 min-w-[13rem]">
                  <div
                    className="cursor-pointer underline"
                    onClick={() => setShowMobileOTP(true)}
                  >
                    Use Mobile instead
                  </div>
                </div>
                {!isModal &&
                  showRegister &&
                  (showSignIn ? (
                    <div className="">
                      <div
                        className="cursor-pointer underline"
                        onClick={() => {
                          setShowSignIn(false);
                        }}
                      >
                        Need to register?
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div
                        className="cursor-pointer underline"
                        onClick={() => {
                          setShowSignIn(true);
                        }}
                      >
                        Need to Sign in?
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            {showLoginError && loginError && (
              <Alert className="mt-4" color="warning">
                {loginError.message}
              </Alert>
            )}
            {showRegisterError && registerError && (
              <Alert className="mt-4" color="warning">
                {registerError.message}
              </Alert>
            )}
            {state.confirmationMessage && (
              <Alert className="mt-4" color="warning">
                {state.confirmationMessage}
              </Alert>
            )}
            {sError && (
              <Alert className="mt-4" color="warning">
                {sError.message}
              </Alert>
            )}
          </div>
        </form>
      ) : (
        <SetDisplayName />
        // <form className="flex flex-col md:mx-16" onSubmit={handleEmailSubmit}>
        //   <div>
        //     <div className="mb-3 text-center text-3xl font-semibold text-black">
        //       Enter Your Email
        //     </div>
        //     <div className="flex flex-col justify-center">
        //       <div className="mb-3 flex justify-center">
        //         <div className="text-xs text-black md:text-sm">
        //           We use your email for password recovery. You can also tell us
        //           to send you reminders as a part of the program.
        //         </div>
        //       </div>
        //       <div className="flex justify-center">
        //         <input
        //           className="form-control m-0 block w-full rounded border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2 text-xl font-normal text-gray-700"
        //           id="email"
        //           type="email"
        //           name="email"
        //           style={{ maxWidth: '600px', minWidth: '350px' }}
        //           value={state.email}
        //           onChange={handleInputChange}
        //         />
        //       </div>
        //     </div>
        //   </div>
        //   <div className="mt-4 flex w-full justify-center">
        //     <Button
        //       style={{
        //         width: '170px',
        //         borderRadius: '2rem',
        //         backgroundColor: 'rgb(30 64 175)',
        //       }}
        //       className="bg-blue-800"
        //       type="submit"
        //       disabled={!state.email || setEmailLoading}
        //     >
        //       Next
        //       <IoArrowForward />
        //     </Button>
        //   </div>
        //   <div className="mt-2 text-center align-baseline text-black">
        //     <div
        //       className="cursor-pointer underline"
        //       onClick={() =>
        //         router.push(program ? `/p/${program?.slug}/today` : `/today`)
        //       }
        //     >
        //       Skip for now
        //     </div>
        //   </div>
        //   <div className="flex justify-center">
        //     {showEmailError && setEmailError && (
        //       <Alert className="mt-4" color="warning">
        //         {setEmailError.message}
        //       </Alert>
        //     )}
        //   </div>
        // </form>
      )}
    </div>
  );
};
